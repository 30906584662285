/**
 * Function to load a script dynamically
 * @param {Array} scripts array of scripts to load dynamically
 * @param {Function} callback optional callback function to run after all scripts are loaded
 */
function injectScripts(scripts, callback) {
  const head = document.getElementsByTagName('head')[0];
  let loaded = 0;

  scripts.forEach((script) => {
    const scriptEl = document.createElement('script');
    scriptEl.src = script;
    scriptEl.onload = () => {
      loaded += 1;
      if (loaded === scripts.length && callback) {
        callback();
      }
    };
    head.appendChild(scriptEl);
  });
}

/**
 * Detects if Vue is loaded and loads it if not
 */
export async function detectAndLoadVue() {
  let VueDependency;
  if (!window.Vue) {
    VueDependency = new Promise((resolve) => {
      injectScripts([`${window.jsDir}dist/vue.min.dist.js`], resolve);
    });
    await VueDependency;
  }
}

/**
 * Trim a trailing slash off a string if it exists
 * @param {string} str string to trim trailing slash from 
 * @returns {string} string with trailing slash removed if it exists
 */
export function trimTrailingSlash(str) {
  if (str.endsWith('/')) {
    return str.slice(0, -1);
  }
  return str;
}

/**
 * Replace pseudo hrefs with actual hrefs if the browser is not a robot
 */
export function replacePseudoHrefLinks() {
  // eslint-disable-next-line max-len
  const userAgentRegex = /bot|googlebot|Googlebot|sitebulb|AhrefsSiteAudit|contentkingapp|semrush|crawler|spider|robot|crawling/i;
  if (userAgentRegex.test(navigator.userAgent) || window.seotoolvisit) {
    return;
  }

  const anchors = [...document.querySelectorAll('a[data-pseudo-href]')];

  anchors.forEach((anchor) => {
    const pseudoHref = anchor.getAttribute('data-pseudo-href');

    if (pseudoHref) {
      const hrefValue = pseudoHref.startsWith('http') || pseudoHref.startsWith('/') 
        ? pseudoHref 
        : `/${pseudoHref}`;
        
      anchor.setAttribute('href', hrefValue);
    }
  });
}

/**
 * Function to copy text to the clipboard
 * @param {string} text the text to copy to the clipboard
 * @param {Function} callback optional callback function to run after text is copied 
 */
export function copyToClipboard(text, callback = () => {}) {
  navigator.clipboard.writeText(text);
  callback();
}
