/**
 *  Validates passwords across forms using jQuery Validate
 *
 * @return {void}
 */
export default function passwordValidation() {
    $.validator.addMethod('strongpassword', function (value, element) {
      return this.optional(element) || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/.test(value);
    });

   var inputStrongPassword = $('#save_password').data('validate-strongpassword') ||
                          $('#passwd').data('validate-strongpassword');
   var inputConfirmationMessage = $('#confirmation').data('validate-confirmation');

   var messages = { 
    'strongpassword': inputStrongPassword,
    'confirmation': inputConfirmationMessage,
    };
   var formToValidate = '.form-to-validate';
  
    $(formToValidate).validate({
      rules: {
        passwd: {
          strongpassword: true,
        },
        confirmation: {
          required: true,
          equalTo: '#passwd',
        },
        'save_password': {
          required: true,
          strongpassword: true,
        },
      },
      messages: {
        passwd: {
          strongpassword: messages.strongpassword,
        },
        confirmation: {
          equalTo: messages.confimation,
        },
        'save_password': {
          strongpassword: messages.strongpassword,
        },
      },
      errorClass: 'registration-form__error-label',
      errorPlacement: function (error, element) {
        error.insertAfter(element);
      },
      highlight: function (element) {
        $(element)
          .addClass('registration-form__input--has-error')
      },
      unhighlight: function (element) {
        $(element)
          .removeClass('registration-form__input--has-error')
      },
      onkeyup: function (element) {
        $(element).valid();
    },
    onfocusout: function (element) {
        $(element).valid();
    },
      submitHandler: function (form, event) {
        if (!$(form).valid()) {
            event.preventDefault();
            return false;
        }
        form.submit();
      },
    });
  }
  